.home__page {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

.home__nav {
  grid-column-start: 1;
}

.home__main {
  grid-column: 2 / span 15;
}

.home__right {
  grid-column: span 4;
}

.collapse-full-height {
  height: 100% !important;
}

.dropdown-enter {
  opacity: 0;
  transform: scale(0.9);
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.dropdown-exit {
  opacity: 1;
}

.dropdown-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.grow-left {
  direction: rtl;
}

.grow-left > * {
  direction: ltr;
}

.gradient-bg {
  background: linear-gradient(
    92.63deg,
    #a09de8 -13.64%,
    #643fbd 26.76%,
    #6643bf 58.79%,
    #abaef0 113.52%
  );
}

.gradient-bg-radial {
  background: radial-gradient(
    42.73% 77.63% at 50% 50%,
    #401c98 0%,
    #643fbd 100%
  );
}

.landing_page-bg {
  background-image: url("../assets/landing_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.landing_page_bg-2 {
  background-image: url("../assets/landing_bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
